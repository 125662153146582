import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-styled-flexboxgrid';
import * as El from './styles';

import '../../assets/css/font-awesome.css';

const OurMethodsBox = ({ dataBox }) => {
  const { title, colorTitle, icon, backgroundTitle, info } = dataBox;

  return (
    <Col xs={12} sm={6} md={4}>
      <El.BoxWrapper>
        <El.Title style={{ backgroundColor: backgroundTitle }}>
          <El.StyledEm className={`fa ${icon}`} />
          <h4 style={{ color: colorTitle }}>{title}</h4>
        </El.Title>
        <El.Info>
          <p>{info}</p>
        </El.Info>
      </El.BoxWrapper>
    </Col>
  );
};

OurMethodsBox.propTypes = {
  dataBox: PropTypes.shape({}),
};

export default OurMethodsBox;
