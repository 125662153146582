import styled from 'styled-components';
import media from '../../tools/mediaQueries';

export const BoxWrapper = styled.div`
  width: 100%;
  background-color: #f2eee2;
  margin-bottom: 20px;

  ${media.md`
    height: 330px;
  `};
`;
export const Title = styled.div`
  padding: 10px;
  text-align: center;

  h4 {
    text-transform: uppercase;
    line-height: 31px;
    font-size: 21px;
    font-family: 'Raleway';
    font-weight: 700;
    margin: 5px 0 0 0;
  }
`;

export const StyledEm = styled.em`
  color: #c1dade;

  &:before {
    font-size: 44px;
    line-height: 58px;
  }
`;

export const Info = styled.div`
  padding: 15px;
  height: auto;
  text-align: center;

  p {
    color: #616161;
    font-size: 17px;
    line-height: 22px;
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 0;
  }
`;
