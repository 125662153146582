import React from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Layout from '../layouts';
import media from '../tools/mediaQueries';

import Hero from '../components/Hero';
import OurMethodBox from '../components/OurMethodBox';

const OurMethod = ({ data }) => {
  const { boxes, list } = data.allOurMethodJson.edges[0].node;

  return (
    <Layout>
    <Grid>
      <Helmet title="STERN & STERN - Our method" />
      <StyledRow>
        <Hero title="our method" />
        <Lead>
          <p>
            STERN & STERN, P.C. is a law firm dedicated to collection and the enforcement of creditor's rights. OUR firm
            offers complete coverage throughout New York state.
          </p>
          <h3 className="bold">NO COLLECTION, NO FEE</h3>
          <h3>
            “We collect on the account <br /> or our services are free!”
          </h3>
        </Lead>
      </StyledRow>
      <StyledRow>{boxes.map(item => <OurMethodBox key={item.title} dataBox={item} />)}</StyledRow>
      <StyledRow>
        <Advantages xs={12}>
          <h5>Stern & Stern will perform all services above AT THE BEST RATES AVAILABLE. </h5>
          <h1>THE ADVANTAGES OF HIRING AN ATTORNEY TO COLLECT YOUR DEBTS </h1>
          <ul>
            {list.map(item => (
              <li>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </Advantages>
      </StyledRow>
    </Grid>
    </Layout>
  );
};

export const query = graphql`
  query OurMethodPage {
    allOurMethodJson {
      edges {
        node {
          boxes {
            title
            icon
            backgroundTitle
            colorTitle
            info
          }
          list
        }
      }
    }
  }
`;

OurMethod.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default OurMethod;

const StyledRow = styled(Row)`
  background-color: #fff;
`;

const Lead = styled.div`
  width: 95%;
  margin: 0 auto;
  font-family: 'Montserrat';
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;

  ${media.sm`
    width: 80%;
    font-size: 21px;
    text-align: justify;
  `};

  ${media.md`
    width: 58%;
  `};

  p {
    margin: 0;
    font-weight: 500;
    color: #2c2c2c;
    line-height: 25px;
  }

  h3 {
    color: #a85701;
    font-weight: 500;
    font-size: 18px;
    text-align: center;

    ${media.sm`
      font-size: 20px;
    `};
  }

  h3.bold {
    font-size: 22px;
    font-weight: 700;
    margin-top: 35px;
    margin-bottom: 0;

    ${media.sm`
      font-size: 24px;
      margin-top: 25px;
    `};
  }
`;

const Advantages = styled(Col)`
  font-family: 'Raleway';
  text-align: center;

  h5 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #4b721d;
    text-shadow: 0 2px 9px rgba(0, 0, 0, 0.3);

    ${media.sm`
      font-size: 38px;
      line-height: 40px;
    `};

    ${media.md`
      font-size: 48px;
      line-height: 48px;
    `};
  }

  li {
    list-style: disc;
    list-style-position: outside;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: #a85701;
    line-height: 24px;

    ${media.sm`
      line-height: 36px;
    `};

    p {
      font-size: 15px;
      line-height: 24px;
      color: #000;
      margin: 0;
      text-align: left;

      ${media.sm`
        font-size: 20px;
        line-height: 26px;
      `};

      ${media.md`
        font-size: 24px;
        line-height: 35px;
      `};
    }
  }
`;
